import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate instead of useHistory

export const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate(); // Use useNavigate for navigation

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://resumeviewer.zappyhire.com/api/signup", // Replace with your backend URL
        { email, password, name }, // Send data as an object
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      // Handle the response as needed
      console.log(response.data);

      // If signup was successful, switch to the login form
      navigate("/"); // Navigate to the login page
    } catch (err) {
      if (err.response) {
        setErrMsg(err.response.data.error);
      } else {
        setErrMsg("An error occurred during signup.");
      }
    }
  };

  return (
    <>
      <div className="auth-form-container">
        <h2>Register</h2>
        <form className="register-form" onSubmit={handleSubmit}>
          <label htmlFor="name">Full name</label>
          <input
            value={name}
            name="name"
            onChange={(e) => setName(e.target.value)}
            id="name"
            placeholder="Full Name"
            autoComplete="off"
            required
          />
          <label htmlFor="email">Email</label>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="youremail@gmail.com"
            id="email"
            name="email"
            autoComplete="off"
            required
          />
          <label htmlFor="password">Password</label>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="********"
            id="password"
            name="password"
            autoComplete="off"
            required
          />
          <div className="reg-button-container">
          <button type="submit">Register</button>
          </div>
        </form>
        <button
          className="link-btn"
          onClick={() => navigate("/")} // Navigate to the login page
        >
          Already have an account? Login here.
        </button>
      </div>
      {errMsg && <p className="error-msg">{errMsg}</p>}
    </>
  );
};

export default Register;
