import React, { useState, useEffect } from "react";
import './ResumeViewer.css';

const ResumeViewer = ({ resumeUrls }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showResumeButton, setShowResumeButton] = useState(true);
  const [showImg, setshowImg] = useState(false);

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === resumeUrls.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? resumeUrls.length - 1 : prevIndex - 1
    );
  };

useEffect(() => {
    // Use setTimeout to update the message after 2000 milliseconds (2 seconds)
    const timeoutId = setTimeout(() => {
      setshowImg(true);
    }, 1000);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, []); //


  const handleViewResume = () => {
    setShowResumeButton(false);
  };

//  const handlePageClick = (Index) => {
//    setCurrentImageIndex(Index);
//  };


  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the context menu from appearing
  };

  const totalPages = resumeUrls.length;

  return (
    <div className="view-container" onContextMenu={handleContextMenu}>
      <div className="resume-content">
        {resumeUrls.length > 0 && (
          <div className="image-viewer-container">
            <img
              className="image-viewer"
              src={resumeUrls[currentImageIndex]}
              alt={`Page ${currentImageIndex + 1}`}
            />
            <div className="carousel-buttons">
              <div className="arrow prev-arrow" onClick={goToPrevImage}></div>
              <div className="page-number-container">
                <div className="page-number-box">
                  {currentImageIndex + 1}/{totalPages}
                </div>
              </div>
              <div className="arrow next-arrow" onClick={goToNextImage}></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResumeViewer;