import { useNavigate } from "react-router-dom";
import axios from "axios";

const useLogoutUser = () => {
  const navigate = useNavigate();

  const logoutUser = async () => {
    try {
      // Make the API call to the logout endpoint
      const response = await axios.get("https://resumeviewer.zappyhire.com/api/logout");

      // Clear the JWT token from localStorage
      localStorage.removeItem("access_token");
      if (response.status === 200 && response.data.message === "Logged out successfully") {
        // Successful logout
        // Replace the current URL with the login URL in the browser history
        window.history.replaceState(null, "", "/");
        // Redirect the user to the login page after successful logout
        navigate("/", { replace: true });
      } else {
        // Handle other scenarios or errors
        console.error("Error logging out:", response.data.error);
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return logoutUser;
};

export default useLogoutUser;
