import React, { useState } from "react";
import "./App.css";
import AppRouter from "./AppRouter";

function App() {
  const [currentForm, setCurrentForm] = useState("login");

  const toggleForm = (formName) => {
    setCurrentForm(formName);
  };

  return (
    <div className="App">
      <AppRouter onFormSwitch={toggleForm} />
    </div>
  );
}

export default App;
