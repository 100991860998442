import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Login from "./Login";
import Register from "./Register"; // Import the Register component
import ResumeUpload from "./ResumeUpload";
import ResumeViewer from "./ResumeViewer";

const AppRouter = ({ onFormSwitch }) => { // Accept onFormSwitch as a prop
  return (
    <Router>
      <Routes>
        {/* IndexRoute will redirect to /login */}
        <Route path="/" element={<Navigate to="/login" />} />

        {/* Route for the login page */}
        <Route path="/login" element={<Login onFormSwitch={onFormSwitch} />} />

        {/* Route for the register page */}
        <Route path="/register" element={<Register onFormSwitch={onFormSwitch} />} />

        {/* Route for the resume upload page */}
        <Route path="/resume_upload" element={<ResumeUpload />} />

        {/* Route for the resume view page */}
        <Route path="/resume_view" element={<ResumeViewer />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
