import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom"; // Import Link for navigation

const LOGIN_URL = "https://resumeviewer.zappyhire.com/api/login"; // Replace with your backend login URL

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        LOGIN_URL,
        { email, password },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      localStorage.setItem("access_token", response.data.access_token);
      // Handle the response as needed
      console.log(response.data);

      // Navigate to the "/resume_upload" route upon successful login
      // (You can redirect to any other route if needed)
      navigate("/resume_upload");
    } catch (err) {
      if (err.response) {
        setErrMsg(err.response.data.error);
      } else {
        setErrMsg("An error occurred during login.");
      }
    }
  };

  return (
    <>
      <div className="auth-form-container">
        <h2 className="login-header">Zappyhire Resume Viewer</h2>
        <h2 className="login-header">Login</h2>
        <form className="login-form" onSubmit={handleSubmit}>
          <label htmlFor="email">Email</label>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="youremail@gmail.com"
            id="email"
            name="email"
            autoComplete="off"
            required
          />
          <label htmlFor="password">Password</label>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="********"
            id="password"
            name="password"
            required
          />
          <div className="button-container">
          <button type="submit">Login</button>
          </div>
        </form>
        <Link to="/register" className="link-btn">Don't have an account? Register here.</Link>
      </div>
      {errMsg && <p className="error-msg">{errMsg}</p>}
    </>
  );
};

export default Login;
