import React, { useState, useEffect } from "react";
import axios from "axios";
import ResumeViewer from "./ResumeViewer";
import { useNavigate } from "react-router-dom";
import useLogoutUser from "./Logout"; // Import the custom React Hook
import history from "./history";

const ResumeUpload = () => {
  const [resumeName, setResumeName] = useState("");
  const [resumeUrls, setResumeUrls] = useState([]);
  const [success, setSuccess] = useState(false);
  const [loggedIn, setLoggedIn] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  // Call the custom React Hook to get the logoutUser function
  const logoutUser = useLogoutUser();

  const handleViewResume = async () => {
    const token = localStorage.getItem("access_token");

    if (!token) {
      console.error("No JWT token found. Please login first.");
      navigate("/login");
      return;
    }

    if (!resumeName) {
      setErrorMessage("Please enter the resume name.");
      setTimeout(() => {
      setErrorMessage("");
      }, 3000);
      return;
    }

    // Set the authorization header with the JWT token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "json",
    };

    try {
      const response = await axios.get(
        `https://resumeviewer.zappyhire.com/api/temp/${resumeName}`,
        config
      );
      // Get the image URLs from the JSON response
      console.log("Resume URLs:", response.data.image_urls);
      const imageUrls = response.data.image_urls;
      const timeoutId = setTimeout(() => {
      setResumeUrls(imageUrls)
      setSuccess(true);
        }, 1500);

      } catch (error) {
      console.error("Error fetching resume:", error);
      setSuccess(false);
      setErrorMessage("Please enter the correct resume name.");
      setTimeout(() => {
      setErrorMessage("");
      }, 3000);

//      const file = new Blob([response.data], { type: "application/pdf" });
//      const fileUrl = URL.createObjectURL(file);
//      setResumeUrl(fileUrl);
//      setSuccess(true);
//    } catch (error) {
//      console.error("Error fetching resume:", error);
//      setSuccess(false);
//      setErrorMessage("Please enter the correct resume name.");
    }
  };

  const handleLogout = () => {
    logoutUser()
      .then(() => {
      // Clear the JWT token from localStorage
      localStorage.removeItem("access_token");

      // Redirect the user to the login page after successful logout
      navigate("/");
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
  };

  // useEffect to handle browser history
  useEffect(() => {
    const handleHistoryChange = () => {
      if (!localStorage.getItem("access_token")) {
        // If the user is not logged in, redirect to the login page
        setLoggedIn(false);
        navigate("/");
      }
    };

    // Listen for changes in the browser's history
    window.addEventListener("popstate", handleHistoryChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handleHistoryChange);
    };
  }, [loggedIn, navigate]);

  useEffect(() => {
  const handleDisableBrowserNavigation = () => {
    if (loggedIn) {
      // If the user is logged in and on the ResumeUpload page, replace the current history entry with a new one
      history.replace("/resume_upload");
      history.block((location) => location.pathname === "/resume_upload");

      // Disable the previous page arrow using pushState and onpopstate event
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function (event) {
        window.history.pushState(null, null, window.location.href);
      };
    } else {
      // If the user is not logged in or on another page, reset the history modifications
      history.replace("/");
      history.block(() => false);

      // Enable the previous page arrow by restoring the onpopstate event to the default behavior
      window.onpopstate = null;
    }
  };

  handleDisableBrowserNavigation();

  // Clean up the history modifications when the component unmounts
  return () => {
    history.replace("/");
    history.block(() => false);

    // Restore the default behavior of the onpopstate event when the component unmounts
    window.onpopstate = null;
  };
}, [loggedIn]);


  return (
    <>
      <div className="background-container">
        <div className="logout-btn-container">
          <button onClick={handleLogout}>Logout</button>
        </div>
        <h1 className="resume-title">Resume Viewer</h1>
        <div className="resume-con">
          <input
            type="text"
            value={resumeName}
            onChange={(e) => setResumeName(e.target.value)}
            placeholder="Enter resume name here"
          />
          <button onClick={handleViewResume}>View Resume</button>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          {success && <ResumeViewer resumeUrls={resumeUrls} />}
        </div>
      </div>
    </>
  );
};

export default ResumeUpload;
